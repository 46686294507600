import * as React from 'react';

import {
    //CloneButton,
    CreateButton,
    FilterButton,
    TopToolbar,
    useTranslate,
    ExportButton, SortButton,
} from 'react-admin';
import {SelectColumnsButton} from "@react-admin/ra-preferences";
import {ButtonFilterAside} from "./CompReact";
import {Box, useMediaQuery} from "@material-ui/core";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ViewListIcon from "@material-ui/icons/ViewList";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import Button from "@material-ui/core/Button";



const ButtonTableOrGrid = ({handleTableOrGrid, table}) => {
    const handleClick = (event, isTable) => {
        event.preventDefault();
        event.stopPropagation();
        handleTableOrGrid(isTable)
    }
    return (
        <Box display={"flex"} mt={'-2px'}>
            <Button
                title={'Select TABLE'}
                color={"primary"}
                // style={{cursor: "pointer", color: "cornflowerblue", fontSize: 32, opacity: !table && 0.3}}
                style={{opacity: !table && 0.3}}
                onClick={(event) =>  handleClick(event,true) }
                startIcon={<ViewListIcon style={{fontSize: 24}}/>}
            >Table</Button>
            <Button
                title={'Select GRID'}
                color={"primary"}
                // style={{cursor: "pointer", color: "cornflowerblue", fontSize: 32, opacity: table && 0.3}}
                style={{opacity: table && 0.3}}
                onClick={(event) => handleClick(event,false) }
                startIcon={<ViewModuleIcon style={{fontSize: 24}}/>}
            >Grid</Button>
        </Box>
    )
}

const TableActions = (props) => {

    const {
        preference,
        columns,
        basePath,
        data,
        create = false,
        create2 = false,
        create2Label = 'create2Label',
        select = true,
        filter = true,
        asideFilter = false,
        exportCsv = false,
        showAsside = true,
        exporterFile = false,
        exporterlabel = 'Export',
        handlerForAllTable = false,
        handlerForAllTable2 = false,
        handlerForAllTable3 = false,
        sortButton = false,
        table,
        handleTableOrGrid  //For BUTTON ButtonTableOrGrid
    } = props

    const translate = useTranslate();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('sm');
    });


    /*document.addEventListener('click', (e) => {
        let icon_aside_card = document.getElementById("icon_aside_card");
        let withinBoundaries = e.composedPath().includes(icon_aside_card);
        if (!withinBoundaries) {
            const aside_card = document.querySelector('#aside_card');
            withinBoundaries = e.composedPath().includes(aside_card);
            if (!withinBoundaries) {
                console.log('withinBoundaries===========>', withinBoundaries)
                aside_card.style.display = 'none'; // скрываем элемент т к клик был за его пределами
            }
        }
    })*/
    const handleFilter = () => {
        let aside_card = document.getElementById("aside_card");
        if (aside_card) {
            if (aside_card.style.display === 'inline-table') aside_card.style.display = 'none'
            else aside_card.style.display = 'inline-table'
        }
    }
    /*const handleCreate = (event,to) => {
        // event.preventDefault();
        // event.stopPropagation();
        console.log('=to===========>', to)
        redirect(to);
    };*/
    //console.log('props==============>', props)
    return (
        <TopToolbar fullWidth>
            <Box display="flex" alignItems={"center"}>
                {handleTableOrGrid && !isSmall
                    && <ButtonTableOrGrid handleTableOrGrid={handleTableOrGrid} table={table}/>
                }

                {asideFilter && <ButtonFilterAside showAsside={showAsside}/>}
                {filter && <FilterButton/>}
                {/*<CloneButton basePath={basePath} record={data} />*/}
                {sortButton && <SortButton fields={sortButton}/>}
                {create && <CreateButton basePath={basePath} record={data}/>}
                {create2 && <CreateButton to={create2} label={create2Label} record={data}/>}
                {/*{create2 &&
                    <Button size="small" color="primary" onClick={(event) => handleCreate(event,create2)}>
                    <AddIcon/>
                    {create2Label}
                </Button>}*/}
                {select && <SelectColumnsButton
                    preference={preference}
                    columns={columns}
                    label={translate('myLocal.columns')}
                />}
                {handlerForAllTable && handlerForAllTable}
                {handlerForAllTable2 && handlerForAllTable2}
                {handlerForAllTable3 && handlerForAllTable3}
                {exporterFile && <ExportButton exporter={exporterFile} label={exporterlabel}/>}
                {exportCsv && <ExportButton label={'Export'}/>}

                {isSmall &&
                    <FilterAltIcon
                        id={'icon_aside_card'}
                        onClick={handleFilter}
                        style={{
                            //color: 'blue',
                            display: 'inline-table',
                            position: 'fixed',
                            top: 68,
                            right: 28,
                            padding: 16,
                            backgroundColor: '#3f51b5',
                            color: 'white',
                            borderRadius: 50,
                            zIndex: 5,
                            cursor: "pointer",
                        }}/>
                }

            </Box>
        </TopToolbar>
    )
};
export default TableActions;
