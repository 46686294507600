import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';

//https://marmelab.com/react-admin/TranslationLocales.html
//import frenchMessages from 'ra-language-french';
import germanMessages from "ra-language-german";
import ukrainianMessages from 'ra-language-ukrainian';
import italianMessages from 'ra-language-italian';
// import hebrewMessages from 'ra-language-hebrew';
// import ArabicMessages from 'ra-language-arabic';
import russianMessages from 'ra-language-russian';

import {localesConfig} from "./config";
import {
    resolveBrowserLocale,
} from 'react-admin';

import {transRu} from "../_translate/transRu";
import {transUk} from "../_translate/transUk";
import {transEn} from "../_translate/transEn";
import {transDe} from "../_translate/transDe";
import {transFr} from "../_translate/transFr";
import {transIt} from "../_translate/transIt";

const messages = {
    uk: { ...ukrainianMessages, ...transUk },//hebrewMessages
    ru: { ...russianMessages, ...transRu },//hebrewMessages
    en: { ...englishMessages, ...transEn},//transEn,//englishMessages,
    de: { ...germanMessages, ...transDe},//transEn,//englishMessages,
    fr: { ...englishMessages, ...transFr},//transEn,//englishMessages,
    it: { ...italianMessages, ...transIt},//transEn,//englishMessages,
    // ru: russianMessages,
    // ar: englishMessages,
    // ar: ArabicMessages,
};
//console.log('============>', messages.he)
const i18nProvider = polyglotI18nProvider(
    locale => messages[locale],
    localesConfig.defaultLocale, // Default locale
    // locale => messages[locale] ? messages[locale] : messages.en,
    resolveBrowserLocale('en', { fullLocale: true }), // 'en' => Default locale when browser locale can't be resolved, { fullLocale: true } => Return full locale
    {allowMissing: true}, //Отключает warning от внешних источников

);

export default i18nProvider
