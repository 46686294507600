import * as React from 'react';
import {
    TextField,
    ReferenceManyField,
    useTranslate, useLocale,
    ReferenceField, AutocompleteInput, ReferenceInput,
} from 'react-admin';

//import {useFormContext} from "react-hook-form";

import {
    //EditableDatagrid,
    RowForm,
    // useCreateRowContext,
    // useEditRowContext
} from "@react-admin/ra-editable-datagrid";
import useMyStyles from "../../utils/useMyStyles";
import {DateFieldComp} from "../../_common/CompReact";

import {useSelectedColumns} from "@react-admin/ra-preferences";
import MyEditableDatagridForReference from "../../_common/MyEditableDatagridForReference";
import PostPagination from "../../_common/PostPagination";
// import {useForm} from "react-final-form";
//import get from 'lodash/get';
import CroppedLongField from "../../_common/CroppedLongField";
// import {useMemo} from "react";
import {Box} from "@material-ui/core";
import {validateReq} from "../../_constants/validateTable";

const transform = (data) => {
    data.source = data.source2;
    delete data.source2;
    return data;
}
const ListEditForm = (props) => {
    // const classes = useMyStyles();
    const {related_id} = props;
    const translate = useTranslate();
    //const locale = useLocale();

    //const record = useRecordContext(props);
    //const refresh = useRefresh();
    const handleSuccess = (response) => {
        console.log('=response===2========>')
        //refresh();
        //window.location.reload();
    };
    //const filterToQuery = searchText => ({for_invoice: `${searchText}`});
    return (
        <>
            <RowForm
                {...props}
                initialValues={{
                    customer: related_id,
                    source2: localStorage.getItem('username'),
                }}
                transform={transform}
                mutationOptions={{onSuccess: handleSuccess}}
                //onSuccess={handleSuccess}
            >
                {/*<NumberField source="id" headerClassName={classes.headerShortWidth}/>*/}
                <ReferenceInput
                    label={translate('User')}
                    source={'user'}
                    reference={'dUser'}
                    //filter={{question$id: id}}
                >
                    <AutocompleteInput optionText={'username'} resettable
                                       validate={validateReq}
                                       fullWidth/>
                </ReferenceInput>
            </RowForm>
        </>
    )
};
const ColumnsForList = (resource, classes, locale) => {
    const translate = useTranslate();

    let columns = {
        /*id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,*/
        user:
            <ReferenceField source="user" reference="dUser"
                            label={translate('User')} link={false}
            >
                <TextField source={'username'}/>
            </ReferenceField>,
        /*source: /!*<TextField source="source" label={translate('Source')}/>,*!/
            <CroppedLongField source="source" long={10} label={translate('Source')}/>,*/
        updated:
            <Box style={{display: "grid"}} label={translate('Updated')} sortby={'updated'} textAlign={'center'}>
                <CroppedLongField source="source" long={10} label={translate('Source')}/>
                <DateFieldComp source="updated" label={translate('Updated')} textAlign={'center'}
                               cellClassName={classes.date_st} showTime={true}/>
            </Box>,

    }
    return columns;
};

const CustomerUser = props => {
    // const {customer_id, record, isSave = true} = props;
    const translate = useTranslate();
    const locale = useLocale();
    const {related_id} = props;
    const classes = useMyStyles();
    // const redirect = useRedirect()
    //const refresh = useRefresh();

    // let invoiceBooking = useSelector((state) => state.admin.resources.invoice_booking)

    const columns = useSelectedColumns({
        preferences: `${'CustomerUser'}list.columns`,
        columns: ColumnsForList('CustomerUser', classes, locale),
        omit: ["nb_views"],
    });

    return (
        <div style={{width: "100%", /*minWidth: 500,*/ marginBottom: 16}}>
            <ReferenceManyField
                pagination={<PostPagination/>}
                perPage={12}
                fullWidth
                label={translate("Customer User")}
                reference="dCustomerUser"
                target="customer"
            >
                <MyEditableDatagridForReference
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    //noDelete={customer_id !== customerFormId}
                    mutationMode="pessimistic" //Подтвердить удаление
                    undoable
                    createForm={<ListEditForm submitOnEnter={false} locale={locale}
                                              related_id={related_id}/>}
                    editForm={<ListEditForm submitOnEnter={false} locale={locale}
                                            related_id={related_id}/>}
                    //rowStyle={postRowStyle}
                >
                    {columns}
                </MyEditableDatagridForReference>
            </ReferenceManyField>

        </div>
    )
}
export default CustomerUser;