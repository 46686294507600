// *https://www.registers.service.gov.uk/registers/country/use-the-api*
// import fetch from 'cross-fetch';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import {apiPathConfig} from "../utils/config";
import {fetchJson as httpClient} from "../utils/keycloak";
import get from 'lodash/get';

export default function Asynchronous2(props) {
    const {
        multiple = false,
        error = false,
        value = null,
        setValue,
        //defaultValue,
        choices = null,
        url = '',
        filter = '',
        getOptionLabel,
        label = '---',
        outputField = '',
        searchField = 'search',
        domain = apiPathConfig.getApiUrl(),
        nameForFilter = '',
    } = props

    const [open, setOpen] = React.useState(false);
    const [valueTyping, setValueTyping] = React.useState('');
    const [options, setOptions] = React.useState(choices ? choices : []);
    const [optionsFilters, setOptionsFilters] = React.useState(choices ? choices : []);
    const [loading, setLoading] = React.useState(false);
    // const loading = open && options.length === 0;


    // const domain = apiPathConfig.getApiUrl()
    const fetchQuery = `${domain}${url}?limit=15&ordering=-id&skip=0${filter}`

    const handleChange = (event) => {
        // console.log('VALUE TYPING =======>', event.target.value)
        setValueTyping(event.target.value)
        // debugger
    }
    const handleChangeA = (event, newInputValue) => {
        //console.log('newInputValue======>', newInputValue)
        let res = null
        if (newInputValue) {
            if (outputField) res = get(newInputValue, outputField, null)
            else res = newInputValue
        }
        setValue(res)
        /*if (newInputValue && newInputValue[outputField] !== undefined) setValue(newInputValue[outputField])
        else setValue(null)*/
    }

    React.useEffect(() => {
        if(multiple && value) {
            let val = value.map((it) => it.id)
            let opt = options.filter(x => val.indexOf(x.id) < 0)
            setOptionsFilters(opt)
        }
        else setOptionsFilters(options)
    }, [options, value]); // eslint-disable-line

    React.useEffect(() => {
        if(choices) setOptions(choices)
    }, [choices]); // eslint-disable-line

    React.useEffect(() => {
        if (choices === null) {
            (async () => {
                setLoading(true)
                const response = await httpClient(`${fetchQuery}&${searchField}=${valueTyping}`);
                // await sleep(1e3); // For demo purposes.
                const res = response.json;
                const results = res.results
                // debugger
                setOptions(Object.keys(results).map((key) => results[key]));
                setLoading(false)
            })();
        }
    }, [setLoading, fetchQuery, valueTyping, searchField]);

    // React.useEffect(() => {
    //     if (!open) {
    //         setOptions([]);
    //     }
    // }, [open]);

    return (
        <Autocomplete
            multiple={multiple}
            value={value}
            //defaultValue={defaultValue}
            id={`${url}-id-autocomplete`}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            // getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={getOptionLabel}
            // options={options}
            options={optionsFilters}
            filterOptions={(option, {inputValue}) => {//Берет, все что пришло, не применяя фильтр
                if(nameForFilter){
                    return option.filter(x => x[nameForFilter].toUpperCase().indexOf(inputValue.toUpperCase()) >= 0)
                }
                return option
            }}
            onChange={handleChangeA}
            // onSelect={handleChangeA}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    error={error}
                    value={valueTyping}
                    onChange={handleChange}
                    onBlur={(event) => {
                        setValueTyping('')
                    }}
                    size={"small"}
                    label={label}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        //style: {padding: "0px"},
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}